{
  "name": "alpenverein",
  "version": "1.2.37",
  "description": "avsoffice",
  "author": "TuGA GMBH",
  "url": "http://www.tuga.it",
  "copyright": "TuGA GMBH",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "build-prod": "node  --openssl-legacy-provider --max_old_space_size=8048 ./node_modules/@angular/cli/bin/ng build --configuration production",
    "build-oauth2": "node  --openssl-legacy-provider --max_old_space_size=8048 ./node_modules/@angular/cli/bin/ng build --configuration oauth2",
    "mergei18n": "node scripts/mergei18n.js",
    "einstellungen": "node .\\scripts\\changeProject.js avsportal",
    "einstellungen-avsportal": "node .\\scripts\\changeProject.js avsportal",
    "einstellungen-kfsportal": "node .\\scripts\\changeProject.js kfsportal",
    "einstellungen-standard": "node .\\scripts\\changeProject.js standard",
    "einstellungen-template": "node .\\scripts\\changeProject.js template",
    "fullUpdate": "node scripts/fullUpdate.js",
    "increaseVersion": "node scripts/increaseVersion.js",
    "gitUpdate": "node scripts/gitUpdate.js",
    "createTag": "git tag V_1_2_37",
    "pushTag": "git push --tags",
    "createUpdate": "node scripts/createUpdate.js avsportal && node scripts/createUpdate.js kfsportal && node scripts/createUpdate.js standard && node scripts/createUpdate.js template",
    "createUpdateClimbOfficePortal": "node scripts/createUpdateClimbOfficePortal.js",
    "backupUpdate": "node scripts/backupUpdate.js",
    "copyUpdate-avsclimbtest": "node scripts/copyUpdate.js avsportal config_avsclimbtest",
    "copyUpdate-avstest": "node scripts/copyUpdate.js avsportal config_avsportaltest",
    "copyUpdate-avs": "node scripts/copyUpdate.js avsportal config_avsportaltest && node scripts/copyUpdate.js avsportal config_avsportal",
    "copyUpdate-kfstest": "node scripts/copyUpdate.js kfsportal test",
    "copyUpdate-kfs": "node scripts/copyUpdate.js kfsportal config_kfsportaltest && node scripts/copyUpdate.js kfsportal config_kfsportal",
    "copyUpdate-scp": "node scripts/copyUpdate.js standard config_scp",
    "copyUpdate-wsv": "node scripts/copyUpdate.js standard config_wsv",
    "copyUpdate-sf": "node scripts/copyUpdate.js standard config_sf",
    "copyUpdate-ksl": "node scripts/copyUpdate.js template config_ksl",
    "copyUpdate-svtirol": "node scripts/copyUpdate.js standard config_svtirol",
    "copyUpdate-fculten": "node scripts/copyUpdate.js standard config_fculten",
    "copyUpdate-tieraerztekammer": "node scripts/copyUpdate.js template config_tieraerztekammer",
    "copyUpdate-scmeranturnen": "node scripts/copyUpdate.js template config_scmeranturnen",
    "copyUpdate-svmoelten": "node scripts/copyUpdate.js standard config_svmoelten",
    "copyUpdatetest": "npm run copyUpdate-avstest && npm run copyUpdate-kfstest",
    "copyUpdate": "npm run copyUpdate-avs && npm run copyUpdate-kfs && npm run copyUpdate-scp && npm run copyUpdate-wsv && npm run copyUpdate-ksl && npm run copyUpdate-svtirol && npm run copyUpdate-fculten && npm run copyUpdate-tieraerztekammer && npm run copyUpdate-scmeranturnen && npm run copyUpdate-svmoelten",
    "sentry-sourcemaps": "sentry-cli releases --org tuga-gmbh --project avs-portal files alpenverein-1_2_37 upload-sourcemaps ./dist/portal",
    "converti18n": "node scripts/converti18n.js",
    "importi18n": "node scripts/importi18n.js"
  },
  "private": true,
  "dependencies": {
    "@angular-devkit/build-angular": "^18.2.10",
    "@angular/animations": "^18.2.9",
    "@angular/cdk": "^18.2.10",
    "@angular/common": "^18.2.9",
    "@angular/compiler": "^18.2.9",
    "@angular/core": "^18.2.9",
    "@angular/forms": "^18.2.9",
    "@angular/platform-browser": "^18.2.9",
    "@angular/platform-browser-dynamic": "^18.2.9",
    "@angular/router": "^18.2.9",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@sentry/angular": "^8.35.0",
    "angular2-uuid": "^1.1.1",
    "axios": "^1.3.4",
    "bic": "^1.0.0",
    "codice-fiscale-js": "^2.3.20",
    "core-js": "^2.5.4",
    "cropperjs": "^1.6.1",
    "devextreme": "24.1",
    "devextreme-angular": "24.1",
    "file-saver": "^2.0.5",
    "form-data": "^4.0.0",
    "i18n-json-to-xlsx-converter": "^2.0.0",
    "iban": "0.0.14",
    "micro-dash": "^8.1.0",
    "moment": "^2.30.1",
    "ng-recaptcha-2": "^14.0.0",
    "ngx-webcam": "^0.4.1",
    "rxjs": "^7.8.1",
    "sha1": "^1.1.1",
    "threaded-lock": "^0.6.1",
    "tslib": "^2.3.0",
    "typescript-collections": "^1.3.3",
    "xmlhttprequest": "^1.8.0",
    "zip-a-folder": "^1.1.5",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-eslint/builder": "^18.4.0",
    "@angular-eslint/eslint-plugin": "^18.4.0",
    "@angular-eslint/eslint-plugin-template": "^18.4.0",
    "@angular-eslint/template-parser": "^18.4.0",
    "@angular/cli": "^18.2.10",
    "@angular/compiler-cli": "^18.2.9",
    "@angular/language-service": "^18.2.9",
    "@types/iban": "^0.0.32",
    "@types/jasmine": "~3.6.0",
    "@types/jasminewd2": "~2.0.3",
    "@types/node": "^18.11.9",
    "@typescript-eslint/eslint-plugin": "^8.12.2",
    "@typescript-eslint/parser": "^8.12.2",
    "eslint": "^9.13.0",
    "eslint-plugin-import": "2.31.0",
    "eslint-plugin-jsdoc": "50.4.3",
    "eslint-plugin-prefer-arrow": "1.2.3",
    "jasmine-core": "~3.8.0",
    "jasmine-spec-reporter": "~5.0.0",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "^1.5.0",
    "protractor": "~7.0.0",
    "ts-node": "~7.0.0",
    "typescript": "~5.4.5"
  }
}